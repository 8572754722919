.comparables-table-row-popup {
    background: var(--color-whiteish-2);
    border-radius: 8px;
    height: auto;
    max-height: 80vh;
    font-family: "Basis Grotesque Pro", Arial;

    .comparables-header {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-white);
        border-bottom: 1px solid var(--color-greyish);
        box-shadow: 0px 4px 24px rgba(43, 70, 86, 0.06);
        position: absolute;

        .close-button {
            position: absolute;
            right: 25px;
        }
    }

    .comparables-content {
        margin: 26px 24px;
        padding-top: 73px;
        padding-bottom: 26px;

        .comparables-visual-content {
            display: flex;
            flex-direction: row;
            height: 300px;
            margin-bottom: 16px;

            .comparables-block {
                &:not(:last-child) {
                    margin-right: 12px;
                }

                &:not(&_quarter) {
                    border-radius: 8px;
                    overflow: hidden;
                }

                &_quarter {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-column-gap: 12px;
                    grid-row-gap: 12px;

                    .image-wrapper {
                        border-radius: 8px;
                        overflow: hidden;
                    }
                }

                flex: 1;
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .comparables-properties-content {
            display: flex;
            flex-direction: row;

            .comparables-properties-content-item {
                &:not(:last-child) {
                    margin-right: 16px;
                }

                flex: 1;
                background-color: var(--color-white);
                border: 1px solid var(--color-whiteish-3);
                border-radius: 8px;
                padding: 20px 24px;
                box-sizing: border-box;

                .comparables-title {
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--color-grey-dark);
                    margin: 0 0 12px 0;
                }

                .comparables-property {
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--color-black-2);
                    margin: 0;
                }
            }
        }

        .comparables-description-content {
            background-color: var(--color-white);
            padding: 20px 24px;
            box-sizing: border-box;
            margin-top: 16px;
            border: 1px solid var(--color-whiteish-3);
            border-radius: 8px;
            max-height: 300px;
            overflow: auto;

            .comparables-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: var(--color-black-1);
                margin-bottom: 12px;
            }

            .comparables-description {
                font-size: 14px;
                line-height: 24px;
                color: var(--color-grey-dark);
            }
        }
    }
}