body {
  margin: 0;
  font-family: 'Open Sans', Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-aqua: #3698af;
  --color-black-1: #363b49;
  --color-black-2: #404657;
  --color-grey-dark: #7e869b;
  --color-grey: #a8acb7;
  --color-greyish: #dfe5eb;
  --color-whiteish-1: #ecf0f4;
  --color-whiteish-2: #f6f8fa;
  --color-whiteish-3: #ebf1f4;
  --color-white: #ffffff;
  --color-red: #a81616;
  /* sizes */
  --width-common: 312px;
  --width-common-half: calc(var(--width-common) / 2);
  --height-common: 18px;
}

code {
  font-family: source-code-pro, Monaco, Consolas, 'Courier New', monospace;
}

.mapboxgl-canvas {
  outline: none;
}

.is-apis-loading {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.is-apis-loading_background {
  background-color: var(--color-black-2);
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.react-datepicker__navigation--years::before {
  border-color: #666;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  left: 11px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation--years-upcoming::before {
  top: 17px;
  transform: rotate(315deg);
}

.react-datepicker__navigation--years-previous::before {
  top: 6px;
  transform: rotate(135deg);
}

.is-apis-loading_rotate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
  background: transparent;
  border: 3px solid var(--color-whiteish-2);
  border-radius: 50%;
  text-align: center;
  line-height: 75px;
  font-family: sans-serif;
  font-size: 20px;
  color: var(--color-aqua);
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px var(--color-aqua);
  box-shadow: 0 0 1220px rgba(0, 0, 0, 0.5);
}
.is-apis-loading_rotate:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid var(--color-aqua);
  border-right: 3px solid var(--color-aqua);
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('./fonts/BasisGrotesquePro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiempos Headline';
  src: url('./fonts/TiemposText-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Tiempos Bold';
  src: url('./fonts/TiemposText-Bold.woff2') format('woff');
}

* ::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  left: 0px;
  top: 0px;
  background: #ebf1f4;
  border-radius: 8px;
}

* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

* ::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  left: 0px;
  top: 0px;
  background: #d2d6dd;
  border-radius: 8px;
}