.custom-input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 56px;
    cursor: text;
  }
  .custom-input-wrapper-error input {
    border: 1px solid var(--color-red);
  }
  
  .custom-input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 32px;
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 0.98rem;
    line-height: 1.75rem;
    border-radius: 8px;
    outline: none;
    border: none;
    font-family: "Basis Grotesque Pro", Arial;
  }

  .custom-input-no-placeholder {
    padding-top: 16px;
  }

  .custom-input-label {
    pointer-events: none;
    width: 100%;
  }

  .custom-input_empty .form-input-text-placeholder {
    font-size: 0.75rem;
    top: -10px;
    line-height: 1rem;
    transition: all 0.1s;
    width: 100%;
  }

  .custom-input_empty .disabled-message {
    width: 100%;
  }

  .form-input-text-placeholder {
    top: 0px;
    font-size: 1rem;
    margin-left: 12px;
    font-family: "Basis Grotesque Pro", Arial;
    font-style: normal;
    position: relative;
    transition: all 0.2s linear;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-grey-dark);
    width: 90%;
  }

  .custom-input-wrapper-disabled {
    opacity: 0.6;
  }
  .form-input-error-message {
    color: rgb(168, 22, 22);
    position: absolute;
    top: -14px;
    width: 90%;
    left: 0;
    white-space: nowrap;

    font-size: 12px;
    font-family: "Basis Grotesque Pro", Arial;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .small-error {
    top: -19px;
  }

  .custom-input-wrapper .copy-icon {
    position: absolute;
    right: 18px;
    top: -7px;
    cursor: pointer;
  }
  
  .custom-input-wrapper .copied-notification {
    position: absolute;
    top: -39px;
    right: 17px;
    background-color: var(--color-aqua);
    padding: 0px 5px 3px;
    color: var(--color-white);
    box-shadow: 0px 4px 8px rgb(43 70 86 / 12%);
    border-radius: 6px;
  }
  
  .custom-input-wrapper .copied-notification::after {
    content: "";
    position: absolute;
    left: 48px;
    top: 25px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-aqua);
    clear: both;
  }
  
  .custom-input-wrapper .copy-wrapper {
    position: relative;
  }
  .disabled-icon-img{
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  .message-hover{
    max-width: 220px;
  }
  .disabled-message{
    user-select: none;
    display:flex;
    position: absolute;
    height: fit-content;
    top: 3px;
    bottom: 0;
    margin: auto;
    font-family: "Basis Grotesque Pro", Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-grey-dark);
    width: 100%;
  }
  
  .disabled-icon-img-wrapper{
    position: absolute;
    left: 2px;
  }

  .autofill-wrapper {
    width: 100%;
    max-height: 150px;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid var(--color-whiteish-1);
    border-top: 0;
    position: absolute;
    top: 52px;
    border-radius: 0 0 15px 15px;
    z-index: 98;
    background-color: var(--color-white);
    box-shadow: 0px 8px 16px rgba(43, 70, 86, 0.16);
  }
  
  .autofill-item {
    cursor: pointer;
    padding: 5px 10px;
    background-color: var(--color-white);
    transition: all 0.2s linear;
  }
  
  .autofill-item:hover {
    background: var(--color-whiteish-2);
  }
  
  .autofill-item:not(:last-child) {
    border-bottom:1px solid var(--color-whiteish-1);
  }
  
  
  
  