.main-popup-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  /* opacity: 0.5; */
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.main-popup {
  border-radius: 8px;
  width: 1390px;
  max-height: 95vh;
  background: var(--color-white);
  z-index: 1000;
  position: relative;
  /* padding-bottom: 48px; */
  /* overflow: auto; */
  height: 100%;
  z-index: 10000;
}

.main-popup-wrapper-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(110, 128, 137, 0.8);
  opacity: 1;
  height: 100vh;
  width: 100vw;
}

.main-popup-content {
  z-index: 10000;
  position: fixed;
}
