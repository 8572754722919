.dropdown-placeholder {
  width: 100%;
  font-size: 1rem !important;
  top: 26px !important;
  transition: all 0.2s linear !important;
}

.dropdown > .form-input-error-message {
  font-size: 0.75rem !important;
  top: -17px !important;
  white-space: nowrap;
}

.dropdown-placeholder-top .dropdown-placeholder {
  font-size: 0.75rem !important;
  top: 16px !important;
}

.dropdown-select-error {
  border: 1px solid var(--color-red);
  border-radius: 8px;
}
