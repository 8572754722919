.map {
  width: 100%;
  position: relative;
}
.map.map_disabled-ui .mapboxgl-control-container {
  display: none;
}

.map .mapboxgl-ctrl-bottom-right {
  display: none;
}

.map .custom-marker {
  cursor: pointer;
}

.map .switch-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.map .custom-marker-popup {
  text-align: center !important;
  pointer-events: none !important;
  position: relative !important;
  top: -40px !important;
}

.map .legend {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #ffffffe6;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
}
