.switch-button_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding: 0px 15px;
  border-radius: 15px;
}
.switch-button_wrapper > span {
  transition: all 0.2s linear;
}
.switch-button_wrapper .grayed-out {
  opacity: 0.5;
}
